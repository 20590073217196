<template>
  <me-form class="me-grid-column-4" :rules="rules" ref="form">
    <me-label width="300px" title="卖方企业名称" prop="ssellermembername">
      <me-input clearable v-model="form.ssellermembername" />
    </me-label>
    <me-label width="300px" title="合同状态" prop="icontractstatus">
      <me-input clearable v-model="form.icontractstatus" />
    </me-label>
    <me-label width="300px" title="合同金额" prop="fgoodsamount">
      <me-input clearable v-model="form.fgoodsamount" />
    </me-label>
    <me-label width="300px" title="合同总额" prop="ftotalamount">
      <me-input clearable v-model="form.ftotalamount" />
    </me-label>
    <me-label width="300px" title="总重量" prop="ftotalweight">
      <me-input clearable v-model="form.ftotalweight" />
    </me-label>
    <me-label width="300px" title="发货金额" prop="fdeliverygoodsfund">
      <me-input clearable v-model="form.fdeliverygoodsfund" />
    </me-label>
    <me-label width="300px" title="发货金额" prop="fdeliveryfund">
      <me-input clearable v-model="form.fdeliveryfund" />
    </me-label>
    <me-label width="300px" title="实际交收总量" prop="fdeliveryweight">
      <me-input clearable v-model="form.fdeliveryweight" />
    </me-label>
    <me-label width="300px" title="开票金额" prop="finvoiceamount">
      <me-input clearable v-model="form.finvoiceamount" />
    </me-label>
    <me-label width="300px" title="合同作废类型" prop="iinvalidatetype">
      <me-input clearable v-model="form.iinvalidatetype" />
    </me-label>
    <me-label width="300px" title="开具发票类型" prop="iopeninvoicetype">
      <me-input clearable v-model="form.iopeninvoicetype" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        sguid: '',
        ssellermembername: '',
        icontractstatus: '',
        fgoodsamount: '',
        ftotalamount: '',
        ftotalweight: '',
        fdeliverygoodsfund: '',
        fdeliveryfund: '',
        fdeliveryweight: '',
        finvoiceamount: '',
        iinvalidatetype: '',
        iopeninvoicetype: ''
      },
      rules: {
        sguid: [
          { required: true, message: '主键不能为空' }
        ],
        ssellermembername: [
          { required: true, message: '卖方企业名称不能为空' }
        ],
        icontractstatus: [
          { required: true, message: '合同状态不能为空' }
        ],
        fgoodsamount: [
          { required: true, message: '合同金额不能为空' }
        ],
        ftotalamount: [
          { required: true, message: '合同总额不能为空' }
        ],
        ftotalweight: [
          { required: true, message: '总重量不能为空' }
        ],
        fdeliverygoodsfund: [
          { required: true, message: '发货金额不能为空' }
        ],
        fdeliveryfund: [
          { required: true, message: '发货金额不能为空' }
        ],
        fdeliveryweight: [
          { required: true, message: '实际交收总量不能为空' }
        ],
        finvoiceamount: [
          { required: true, message: '开票金额不能为空' }
        ],
        iinvalidatetype: [
          { required: true, message: '合同作废类型不能为空' }
        ],
        iopeninvoicetype: [
          { required: true, message: '开具发票类型不能为空' }
        ]
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectContractByPrimary', 'updateContract' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectContractByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    },
    async save () {
      await this.$refs.form.validate()
      await this.updateContract(this.form)
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>
